@import "_variables.scss";
.custom-scrolling {
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--primaryAlt);
        border-radius: 8px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: rgba(107, 114, 128, 0.6);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        &:hover {
            background-color: rgba(107, 114, 128, 1);
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
        }
    }
}
