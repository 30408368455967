// Material Icons Size Customization
.mat-icon {
    height: 20px;
    width: 20px;
  }


  .mat-icon--sm {
    height: 15px !important;
    width: 15px !important;
  }

  .mat-icon--tiny {
    font-size: 15px;
    line-height: 15px;
    height: 15px;
    width: 15px;
  }

  .mat-icon-lg {
    transform: scale(1);
  }

  .mat-icon-1x {
    transform: scale(1.25);
  }

  .mat-icon-2x {
    transform: scale(1.5);
  }

  .mat-icon-3x {
    transform: scale(2);
  }

  .mat-icon-4x {
    transform: scale(2.5);
  }
