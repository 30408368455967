//? Assigning global variables to scss variables to be integrated to the system
// @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
@import "@angular/material/_theming.scss";

:root {
  --primary: #e41f7b;
  --primaryAlt: #e3e3e3;
  --accent: #f7f7f7;
  --accentDark: #b6b6b6;
  --accentAlt: #363636;
  --success: #e41f7b;
  --warn: #f9aa33;
  --danger: #cf000f;
  --error: #f44336;
  --lightBlack: #323232;
  --mainFont: "Roboto", sans-serif;
  --gray: #232f34;
  --lightBlack: #323232;
  --navBarHeight: 61px;
}
