$form_dialog_small: 35vw;
$form_dialog_normal: 45vw;
$form_dialog_large: 80vw;

.form-dialog {

  .mat-dialog-container {
    min-width: 30vw;
    max-width: $form_dialog_normal;
    max-height: 100vh;
    border-radius: 0;

    @media screen and (max-width: 768px) {
      max-width: 90vw;
    }
  }

  &--large .mat-dialog-container {
    min-width: 50vw;
    max-width: $form_dialog_large;
  }

  &--small .mat-dialog-container {
    max-width: $form_dialog_small;
  }

  &--slideShow .mat-dialog-container {
    min-height: 90vh;
    width: 100vw !important;
  }

  // On Mobile Screen Make The Modal Form Display Correctly
  @media screen and (max-width: 768px) {
    &--small .mat-dialog-container {
      max-width: none;
    }
  }


  .mat-dialog-content {
    min-height: auto;
  }
}
