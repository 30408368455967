@import "_variables.scss";
@import "./components/buttons.component";
@import "./components/dialogs.component";
@import "./components/snackbars.component";
@import "./components/tables.component";
@import "./components/cards.component";
@import "./components/custom-scrollbar.component";
@import "./components/forms.component";
@import "./components/outout-icons";
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

a {
  color: var(--primary);
  transition: all ease-in-out 0.2s;

  &:hover {
    text-decoration: underline;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: auto;
}

.gray-color {
  color: #949494;
}
.mat-drawer-container, .mat-drawer-content{
  overflow: hidden !important;
}

.unreadNotification::before {
  content: "";
  background: red;
  width: 10px;
  height: 10px;
  color: red;
  border-radius: 50%;
  margin-right: 6px;
  display: inline-block;
}

:focus {
  outline: 0 !important;
}

// .themeIcon {
//     &:hover {
//         color
//     }
// }

.min-100 {
  min-width: 100px !important;
}

.min-200 {
  min-width: 200px !important;
}

.inner-wrapper {
  min-height: calc(100vh - 120px);

  @media screen and (max-height: 660px) {
    min-height: calc(100vh - 200px) !important;
  }
}

h1,
h2 {
  font-family: var(--mainFont) !important;
}

.value {
  word-break: break-all;
}

.value-inline {
  word-break: keep-all;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--primary);
  // margin-top: 0.2rem;
}

.ps__thumb-y {
  background-color: var(--primary) !important;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 8px !important;
  right: 2px;
  position: absolute;
}

.owl-theme .owl-dots {
  transform: rotate(-90deg);
  bottom: 0;
  left: 50%;
  position: absolute;
}

.logo{
  height: auto;
  max-height: 30rem;
  object-fit: cover;
  &-150{
    height: 150px;
  }
}

