span[matsuffix] {
    color: var(--accentDark);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: var(--primary);
}

.mat-header-cell {
    background: #e7f1ff;
    color: #000 !important;
    font-weight: 500 !important;
}

.mat-checkbox-inner-container {
    margin-top: 3px !important;
}

.mat-header-row {
    border: none !important;
    margin-bottom: 12px !important;
}

.mat-row {
    border: none !important;
    background-color: #fff !important;
    margin-bottom: 12px!important;
}

.mat-row {
    transition: all ease-in-out 0.2s;
    &:hover {
        background-color: var(--accent);
    }
}

.mat-dialog-container {
    border-radius: 0 !important;
}

.mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
    display: flex;
    align-items: center;
}

::ng-deep .mat-form-field-outline-start {
    border-radius: 28px 0 0 28px !important;
    min-width: 28px !important;
}

::ng-deep .mat-form-field-outline-end {
    border-radius: 0 28px 28px 0 !important;
}

.mat-drawer-transition .mat-drawer-content {
    // z-index: 99 !important;
    overflow: initial !important;
}

mat-chip.mat-chip {
    border-radius: 0 !important;
    padding: 0.8rem 0.5rem !important;
    min-height: auto !important;
    font-size: 0.8rem !important;
    background: var(--primary) !important;
    color: var(--accent) !important;
}

.mat-menu-content:not(:empty) {
    width: auto;
    min-width: 130px !important;
}

mat-icon svg {
    width: 70%;
    margin: auto;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
    background-color: var(--primary) !important;
    // margin-top: 0.2rem;
}

.mat-slide-toggle-bar {
    // height: 20px !important;
    // border-radius: 50px!important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
    // background-color: var(--accent);
}

.mat-tooltip {
    /* your own custom styles here */
    /* e.g. */
    color: var(--accent) !important;
    border-radius: 0 !important;
    background: var(--accentAlt) !important;
    text-transform: none;
}

.mat-checkbox .mat-ripple-element {
    background-color: #b3b3b3 !important;
}

.mat-checkbox-label {
    color: #b3b3b3;
}

// mat-cell:last-of-type, mat-header-cell:last-of-type, mat-footer-cell:last-of-type{
//   min-width: 100px !important;
// }
.red-tooltip {
    border-radius: 0 !important;
    text-transform: none;
    background: var(--primary) !important;
}

.mat-footer-row,
.mat-header-row,
.mat-row {
    display: inline-flex;
    min-width: 100%;
}

.mat-calendar-body-selected {
    background-color: var(--primary) !important;
    color: #ffffff !important;
}

.mat-list-option:hover,
.mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
    background: rgb(247 247 247 / 52%);
}

mat-cell:last-of-type,
mat-header-cell:last-of-type,
mat-footer-cell:last-of-type {
    padding-right: 10px !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--primary);
}

.mat-tab-label {
    color: #66788A !important;
}


/* Styles for the active tab label */

.mat-tab-label.mat-tab-label-active {
    color: var(--primary) !important;
}

.mat-tab-label .mat-tab-label-content {
    font-size: 1.4rem !important;
}


/* Styles for the ink bar */

.mat-ink-bar {
    background-color: var(--primary) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: auto;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #e41f7b !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #fff !important;
}

.cdk-overlay-container .cdk-overlay-pane .filterSelect {
    margin-top: 7%;
    min-width: 100% !important;
}

.cdk-overlay-pane {
    transform: none !important;
    margin: 27px 0px 2rem -11px;
}

.mat-drawer.mat-drawer-side {
    z-index: 1 !important;
}