button:focus {
  outline: 0 !important;
}

button[hidden] {
  display: none !important;
}
.btn-default {
  @apply rounded-full;
  min-width: 170px;
  font-weight: lighter;
  min-height: 45px;
}

button.action-btn {
  @extend .btn-default;
  background-color: var(--primary);
  color: white;
  &.mat-button-disabled,
  &:disabled {
    background-color: var(--accent);
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.5) !important;
  }
}

button.action-btn-2 {
  @extend .btn-default;
  background-color: var(--accentAlt);
  color: white;
  text-transform: uppercase;

  &.mat-button-disabled {
    background-color: var(--accent);
  }
}

button.cancel-btn {
  @extend .btn-default;
  background-color: var(--accent);
}

button.add-btn {
  border-radius: 6px;
  background-color: var(--primary);
  color: white;
  font-weight: 400;
}

button.remove-all-btn {
  color: #000;
  font-weight: 400;
  border: 2px solid var(--primary);
  margin-left: 16px !important;
}

button.confirm-delete-button {
  border-radius: none;
  background-color: var(--primary);
  color: white;
  font-weight: 400;
}

button.cancel-delete-button {
  border-radius: none;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  color: #000;
  font-weight: 400;
}

button.form-action-btn {
  @extend .btn-default;
  @extend .action-btn;
  width: 160px;
  height: 40px;
  border-radius: 20px;
  font-weight: 600 !important;
  margin-right: 20px !important;
}
button.form-cancel-btn {
  @extend .btn-default;
  @extend .cancel-btn;
  width: 160px;
  height: 40px;
  background: #dfe2e6;
  border-radius: 20px;
  font-weight: 600;
  color: #5e5e5e;
}
